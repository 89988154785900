import React, { useState, useContext, useEffect } from 'react';
import withPermissions from "../../../../hoc/withPermissions";
import MainLoader from '../../../../components/Loader/MainLoader';
import AllTableData from '../../../../components/Dashboard/AllTableData';
import { Link, useNavigate } from 'react-router-dom';
import { LayoutContext } from '../../../../Providers/LayoutProvider';
import { useVulnerabilityListById } from '../../../../hooks/asset';
import { getAllDataFromPages } from '../../../../actions/commonActions';
import { useInView } from 'react-intersection-observer';
import Button from '../../../../components/Button';
import VulnerabilityStatus from './VulnerabilityStatus';
import { useValidateLogin } from '../../../../hooks/auth';
import { useGenerateQualysReport } from '../../../../hooks/report';
import { AlertContext } from '../../../../components/SuccessOrFailedModal';
import { getRiskColor } from '../../../../utils/constants/findings';



const AllVAVulnerabilities = () => {
    const [tableSearch, setTableSearch] = useState(null);
    const [tableState, setTableState] = useState(null);
    const [tableFilters, setTableFilter] = useState({
        Status: ['new', 'active', 're-opened', 'retest-ready'],
        Type: ['confirmed']
    });

    const navigate = useNavigate();
    const { mainUrl } = useContext(LayoutContext);
    const [popup] = useContext(AlertContext);

    const reportMutation = useGenerateQualysReport();

    const { data: userData } = useValidateLogin();
    
    const userType = userData?.user?.user_type;
    let companyId;
    if (userType === 'customer') {
        companyId = userData?.user?.currentCompanyId;
    }

    let { isFetching, data, fetchNextPage, hasNextPage } = useVulnerabilityListById(
        {
            companyId,
            tableSearch, sortBy: tableState?.sortBy,
            ...(tableFilters && tableFilters.Asset?.length ? { selectedAssetIds: tableFilters.Asset }: []),
            ...(tableFilters && tableFilters.IP?.length ? { ip: tableFilters?.IP }: []),
            ...(tableFilters && tableFilters["PCI Vulnerability"] ? { pci_vulnerabilities: tableFilters["PCI Vulnerability"] }: []),
            ...(tableFilters && tableFilters.Severity ? { severity: tableFilters.Severity}: []),
            ...(tableFilters && tableFilters.Status ? { status: tableFilters.Status}: []),
            ...(tableFilters && tableFilters.Type ? { type: tableFilters.Type}: []),
        }
    );

    useEffect(() => {
        const filterOptions = {};
        if (Object.keys(filterOptions).length) {
            setTableFilter(filterOptions);
        }
    }, []);

    const vulnerabilityListData = getAllDataFromPages(data?.pages, "vulnData")
    // const vulnerabilityDataCount = data?.pages[0]?.vulnDataLength;
    // const scanCount = data?.pages[0]?.scanCount;
    const listOfIps = data?.pages[0]?.listOfIPs;
    const allAssets = data?.pages[0]?.allAssets;
    const confirmVulnerabilityCount = data?.pages[0]?.confirmVulnerabilityCount;

    const formattedIps = [];
    const collectedIps = [];
    if (listOfIps) {
        listOfIps.map((ip) => {
            formattedIps.push({
                id: ip,
                name: ip,
            });
            collectedIps.push(ip);
        });
    }

    const formattedAssets = [];
    if (allAssets) {
        allAssets.map((asset) => {
            formattedAssets.push({
                id: asset?.platformAssetId,
                name: asset?.assetName,
            });
        });
    }
    
    const { ref, inView } = useInView()
    useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [inView])

    let tableData = vulnerabilityListData?.map((item, index) => {
        return ({
            qid: item?.qid ? item?.qid?.qid : '',
            asset: item,
            title: item,
            ip: item.ip,
            type: item.type,
            severity: item.severity,
            status: item.status,
            riskScore: item?.riskScore ? Number(item?.riskScore) : '',
            port: item.port,
            cveId: item?.qid ? item?.qid?.cve ? item?.qid?.cve : [] : [],
            pciVuln: item?.qid ? item?.qid?.pci_flag : '',
            actions: item,
        })
    })

    let columns = [
        {
            Header: 'QID',
            accessor: 'qid',
        },
        {
            Header: 'Asset',
            accessor: 'asset',
            disableSortBy: true,
            Cell: props => {
                const assetName = props?.value?.assetName;
                const assetId = props?.value?.platformAssetId;
                return (
                    <Link className={"link"} to={`${mainUrl}/all_va/${assetId}/dashboard/`}>
                        {assetName}
                    </Link>
                )
            },
        },
        {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: true,
            Cell: props => {
                const title = props?.value?.qid?.title;
                const uniqueVulnId = props?.value?.uniqueVulnId;
                const assetId = props?.value?.platformAssetId;
                return (
                    <Link className={"link"} to={`${mainUrl}/va/all_findings/${uniqueVulnId}/dashboard?assetId=${assetId}`}>
                        {title}
                    </Link>
                )
            },
        },
        {
            Header: 'IP',
            accessor: 'ip',
            disableSortBy: true,
        },
        {
            Header: 'Type',
            accessor: 'type',
            Cell: props => {
                const type = props?.value;
                return (
                    type === 'info' ?
                    <span className="text-camel-case">Information Gathering</span>
                    : 
                        <span className="text-camel-case">{type}</span>
                )
            },
        },
        {
            Header: 'Severity',
            accessor: 'severity',
            Cell: props => {
                const severity = props?.value;
                return (
                    <span className="text-camel-case">{severity}</span>
                )
            },
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: props => {
                const status = props?.value;
                return (
                    <span className="text-camel-case">{status === "re-opened" ? status : status.split('-').join(' ')}</span>
                )
            },
        },
        {
            Header: 'Risk score',
            accessor: 'riskScore',
            Cell: props => {
                return (<span className={`${getRiskColor(props?.value)}`}>{props?.value}</span>)
            }
            
        },
        {
            Header: 'Port',
            accessor: 'port',
        },
        {
            Header: 'CVE ID',
            accessor: 'cveId',
            disableSortBy: true,
            Cell: props => {
                const cveIds = props?.value;
                return (
                    cveIds.map((cve, index) => (
                        `${cve.id}${cveIds.length !== (index + 1) ? ', ' : ''}`
                    ))
                )
            },
        },
        {
            Header: 'PCI Vuln',
            accessor: 'pciVuln',
            disableSortBy: true,
            Cell: props => {
                const pciVuln = props?.value;
                return (
                    pciVuln ? 'Yes' : 'No'
                )
            },
        },
    ];

    const statusValues = ["new", "active", "re-opened", "fixed", "retest-ready", "false-positive"];
    const severityValues = ["urgent", "critical", "serious", "medium", "minimum"];
    const typeValues = ["confirmed"];

    const filterOptions = [
        {
            name: "Asset",
            type: "dropdown",
            filterValues: formattedAssets,
            firstFilter: true,
            multiSelector: true,
            fullWidth: true,
            isClearable: true,
        },
        {
            name: "IP",
            type: "dropdown",
            filterValues: formattedIps,
            multiSelector: true,
            fullWidth: true,
            isClearable: true,
        },
        {
            name: "Severity",
            type: "dropdown",
            filterValues:
                [
                    {
                        "name": "Urgent",
                        "id": "urgent",
                    },
                    {
                        "name": "Critical",
                        "id": "critical",
                    },
                    {
                        "name": "Serious",
                        "id": "serious",
                    },
                    {
                        "name": "Medium",
                        "id": "medium",
                    },
                    {
                        "name": "Minimum",
                        "id": "minimum",
                    },

                ],
            multiSelector: false,
        },
        {
            name: "Type",
            type: "option", 
            filterValues: ["Confirmed", "Potential", "Info"],
            defaultSelected: ['confirmed'],
            selectedValues: ['confirmed'],
            multiSelector: true,
        },
        {
            name: "Status",
            type: "option",
            filterValues: ["New", "Active", "Re-Opened", "Fixed", "Retest Ready", "False Positive"],
            defaultSelected: ["new", "active", "re-opened", "retest ready", "fixed"],
            selectedValues: ["new", "active", "re-opened", "retest ready", "fixed"],
            multiSelector: true,
        },
        {
            name: "PCI Vulnerability",
            type: "option",
            filterValues: ["Yes", "No"],
            multiSelector: true,
            lastFilter: true,
        }
    ]

    const onTableChange = (tableState) => {
        setTableState(tableState)
    }

    const handleCSVReport = async () => {
        const payload = {
            report_type: 'asset_vulnerability_assessment_report',
            createReport: true,
        };
        if (companyId) {
            payload.companyId = companyId
        }
        if (tableFilters && tableFilters?.Asset?.length) {
            payload.assetIds = tableFilters?.Asset;
        }
        const report_details = {
            search: tableSearch, 
        };
        if (tableFilters && tableFilters?.IP?.length) {
            report_details.ip = tableFilters?.IP;
        } else {
            report_details.ip = collectedIps;
        }
        if (tableFilters && tableFilters?.Severity?.length) {
            report_details.severity = tableFilters?.Severity;
        } else {
            report_details.severity = severityValues;
        }
        if (tableFilters && tableFilters?.Status?.length) {
            report_details.status = tableFilters?.Status;
        } else {
            report_details.status = statusValues;
        }
        if (tableFilters && tableFilters?.Type?.length) {
            report_details.type = tableFilters?.Type;
        } else {
            report_details.type = typeValues;
        }
        if (tableFilters && tableFilters['PCI Vulnerability']?.length) {
            report_details.pci = tableFilters['PCI Vulnerability'];
        } else {
            report_details.pci = ['yes', 'no'];
        }

        payload.report_details = report_details;

        const response = await reportMutation?.mutateAsync(payload)

        // Check if the response contains the S3 URL
        if (response && response?.success && response?.filePresignedUrl) {
            // Trigger the download by navigating to the S3 URL
            handlePopup()
            window.location.href = response?.filePresignedUrl;
        } else {
            popup({
                title: "Failed",
                description: "There was an issue while generating the report! Please try again.",
                variant: "error"
            })
        }
    };

    const handlePopup = () => {
        popup({
          title: 'Success',
          description: 
                    <>
                        The report has been generated. Download should start automatically!
                    </>,
          variant: "success"
        });
    };

    const disabled = tableData?.length === 0;

    return (
        <>
            {(reportMutation.isLoading || isFetching) && <MainLoader />}
            <div>
                <VulnerabilityStatus count={confirmVulnerabilityCount} assetList={allAssets}/>
                <div className='all-va-findings'>
                    <AllTableData
                        title={"All Vulnerability Assessment Findings"}
                        tableClassName={`lg:!max-h-[calc(100vh-340px)]`}
                        searchText={"Search for any finding..."}
                        description={"See the list of all your findings below"}
                        columns={columns}
                        tableData={tableData}
                        dependencyArray={[vulnerabilityListData]}
                        onTableChange={onTableChange}
                        onSearch={value => setTableSearch(value)}
                        setSearch={setTableSearch}
                        filterEnabled={true}
                        filterOptions={filterOptions}
                        setFilter={setTableFilter}
                        hasNextPage={hasNextPage}
                        moreRef={ref}
                        exportEnabled={true}
                        handleExport={handleCSVReport}
                        disableExportBtn={disabled}
                    />
                </div>
            </div>
        </>
    );
};

export default withPermissions(AllVAVulnerabilities);